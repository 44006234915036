<template>
  <main class="w-full">
    <section
      class="relative mt-8 md:mt-16 grid grid-cols-1 md:grid-cols-2 gap-x-10 xl:gap-x-16 container"
    >
      <div class="">
        <img
          :src="artworkInfo.artWorkImage"
          alt=""
          class="w-full h-96 object-cover"
        />
      </div>
      <div class="mt-4 md:mt-0">
        <div class="">
          <p class="text-2xl text-grey-900 font-bold">
            {{ artworkInfo.artworkName }}
          </p>
          <p class="mt-4 text-sm leading-7">
            {{ artworkInfo.artworkDec }}
          </p>
        </div>
        <div class="grid grid-cols-2 mt-8 md:mt-12 gap-y-12 md:gap-y-20">
          <div class="">
            <p class="text-grey-700 text-xs tracking-widest uppercase">
              DESIGNER
            </p>

            <router-link
              :to="{
                name: 'producer',
                params: { id: artworkInfo.producerId },
              }"
              class="mt-3 text-lg text-grey-900 font-bold underline block"
            >
              {{ artworkInfo.producerName }}
            </router-link>
          </div>

          <div class="">
            <p class="text-grey-700 text-xs tracking-widest uppercase">
              RELEASE DATE
            </p>
            <p class="mt-3 text-lg text-grey-900 font-bold">
              {{ artworkInfo.releaseDate }}
            </p>
          </div>

          <div class="">
            <p class="text-grey-700 text-xs tracking-widest uppercase">
              ARTWORK GENRE
            </p>
            <p class="mt-3 text-lg text-grey-900 font-bold block">
              {{ artworkInfo.artworkGenreName }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="choose_your_medium" class="container mt-16 md:mt-36">
      <div class="text-center">
        <div
          class="text-xs font-normal text-grey-700 tracking-widest uppercase"
        >
          Available as
        </div>
        <div class="text-2xl font-bold text-grey-900">choose your medium</div>
      </div>

      <div
        v-if="prudactsArtworks.length > 0"
        class="mt-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-3 lg:gap-x-4 gap-y-16"
      >
        <router-link
          :to="{ name: 'product', params: { id: prudactsArtwork.productId } }"
          class="group"
          :key="index"
          v-for="(prudactsArtwork, index) in prudactsArtworks"
        >
          <div
            class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96 group-hover:border-grey-300 transform duration-700"
          >
            <img :src="prudactsArtwork.image" alt="" class="w-full h-full" />
          </div>
          <p class="mt-4 text-sm text-grey-500 font-normal block text-center">
            {{ prudactsArtwork.productName }}
          </p>
          <p class="mt-2 block text-center">
            <span class="text-lg text-grey-900 font-bold block">{{
              prudactsArtwork.producerName
            }}</span>
      
          </p>
        </router-link>
      </div>
      <div v-else class="text-center text-grey-300 mt-8">
        The products are not published yet.
      </div>
    </section>

    <section id="Featured-artworks" class="container mt-32 xl:mt-44">
      <div class="text-center">
        <div
          class="text-xs font-normal text-grey-700 tracking-widest uppercase"
        >
          you might also like
        </div>
        <div class="text-2xl font-bold text-grey-900">Related Artworks</div>
      </div>

      <div
        class="mt-12 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8"
        id="v-for"
      >
        <a
          :href="
            $router.resolve({
              name: 'artwork',
              params: { id: artwork.artworkId },
            }).href
          "
          :key="index"
          v-for="(artwork, index) in artworks"
        >
          <div
            class="border border-grey-100 p-8 md:p-10 lg:p-20 block group-hover:border-grey-300 transform duration-700"
          >
            <img
              :src="artwork.artWorkImage"
              alt=""
              class="w-full h-96 object-cover"
            />
          </div>
          <p
            class="mt-6 text-xl xl:text-2xl text-grey-900 font-bold block text-center"
          >
            {{ artwork.artworkName }}
          </p>
          <p class="mt-4 text-sm text-grey-600 font-semibold block text-center">
            {{ artwork.producerName }}
          </p>
        </a>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  components: {},
  created() {
    window.scroll(0, 0);
    this.GetArtworkById();
  },
  data() {
    return {
      artworkId: this.$route.params.id,

      producerId: "",
      artworkGenreId: "",
      artworks: [],
      prudactsArtworks: [],
      artworkInfo: {
        artWorkImage: "",
        artworkDec: "",
        artworkGenreName: " ",
        artworkName: "",
        imageName: "",
        releaseDate: "",
        producerName: "",
      },
    };
  },
  methods: {
    GetArtworkById() {
      this.$http.artwroksService
        .GetArtworkById(this.artworkId)
        .then((res) => {
          this.data = res.data;
          this.artworkInfo = res.data.result;

          this.artworkGenreId = this.artworkInfo.artworkGenreId;
          this.producerId = this.artworkInfo.producerId;

          this.GetProductsArtwork();
          this.GetArtworks();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetProductsArtwork() {
      this.$http.artwroksService
        .GetProductsArtwork(this.producerId)
        .then((res) => {
          this.data = res.data;
          this.prudactsArtworks = res.data.result.prudactsArtworks;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetArtworks() {
      this.$http.artwroksService
        .GetArtworks(this.artworkGenreId,this.artworkId)
        .then((res) => {
          this.data = res.data;
          this.artworks = res.data.result.artworks;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
